import React, { Fragment, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useForm } from "react-hook-form";
import { Card, CardBody, CardHeader, Form, FormGroup, Input, Label, Row, Col } from 'reactstrap';
import { H4, H6, Image, Btn } from '../../../../AbstractElements';
import config from '../../../../config';


const TenantDtl = () => {

    const manageTenant = localStorage.getItem("manageTenants");
    const manageGeneralSettings = localStorage.getItem("manageGeneralSettings");
    const accessTenant = localStorage.getItem("accessTenants");
    const accessGeneralSettings = localStorage.getItem("accessGeneralSettings");

    const concessionaire_id = localStorage.getItem("concessionaireId");

    if ((manageTenant !== "1" && manageGeneralSettings !== "1" && accessTenant !== "1" && accessGeneralSettings !== "1")) {
        alert('You are not authorized to view this page');
        window.history.back(); // Navigate back to the previous URL
    }

    const { register, formState: { errors } } = useForm();
    const [tenant, setTenant] = useState({});
    const [concessionaire_name, setConcessionaireName] = useState('');
    const [concessionaire_code, setConcessionaireCode] = useState('');
    const [country_name, setCountryName] = useState('');
    const [property_name, setPropertyName] = useState('');
    const [property_mall_code, setPropertyMallCode] = useState('');
    const [category_name, setCategoryName] = useState('');
    const [subcategory_name, setSubcategoryName] = useState('');
    const [tenant_created_date, setCreatedDate] = useState('');
    const [tenant_modified_date, setModifiedDate] = useState('');
    const [tenant_lease_startdate, setLeaseStartDate] = useState('');
    const [tenant_lease_enddate, setLeaseEndDate] = useState('');
    const [createdBy, setCreatedBy] = useState('');
    const [revenue_method, setRevenueMethod] = useState('');
    const [deliveryPartners, setDeliveryPartners] = useState([]);
    const [charges, setCharges] = useState([]);
    const { id } = useParams();
    const navigate = useNavigate();

    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    useEffect(() => {
        axios.get(`${config.hostname}/tenant/${id}`, configheader)
            .then((response) => {
                setTenant(response.data);
                const concessionaireName = response.data.tenant_concessionaire_id.concessionaire_name;
                setConcessionaireName(concessionaireName);
                const concessionaireCode = response.data.tenant_concessionaire_id.concessionaire_code;
                setConcessionaireCode(concessionaireCode);
                const CountryName = response.data.tenant_country_id.country_name;
                setCountryName(CountryName);
                const propertyName = response.data.tenant_property_id.property_name;
                setPropertyName(propertyName);
                const propertyMallCode = response.data.tenant_property_id.property_mall_code;
                setPropertyMallCode(propertyMallCode);
                const categoryName = response.data.tenant_category_id.category_name;
                setCategoryName(categoryName);
                const subcategoryName = response.data.tenant_subcategory_id.subcategory_name;
                setSubcategoryName(subcategoryName);
                const revenueMethod = response.data.tenant_revenue_share_method_id.description;
                setRevenueMethod(revenueMethod);
                const createddate = new Date(response.data.tenant_created_date).toISOString().split('T')[0];
                setCreatedDate(createddate);
                const modifiedDate = new Date(response.data.tenant_modified_date).toISOString().split('T')[0];
                setModifiedDate(modifiedDate);
                const leaseStartDate = new Date(response.data.tenant_lease_startdate).toISOString().split('T')[0];
                setLeaseStartDate(leaseStartDate);
                const leaseEndDate = new Date(response.data.tenant_lease_enddate).toISOString().split('T')[0];
                setLeaseEndDate(leaseEndDate);
                const tenantcreatedBy = response.data.tenant_created_by.userFirstName
                setCreatedBy(tenantcreatedBy);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [])

    useEffect(() => {
        axios.post(`${config.hostname}/tenantdeliverypartner/getdeliverypartner`, { tenant_id: id }, configheader)
            .then((response) => {
                setDeliveryPartners(response.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }, [])

    useEffect(() => {
        axios
            .post(`${config.hostname}/concessionairecharges/getcharges`, { concessionaire_id: concessionaire_id }, configheader)
            .then((response) => {
                const transformedData = response.data.map(item => ({
                    other_charge_id: item.other_charge_id._id,
                    display_name: item.display_name,
                    charge_value: item.charge_value,
                    charge_name: item.other_charge_id.charge_name
                }));
                setCharges(transformedData);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    // useEffect(() => {
    //     axios.post(`${config.hostname}/tenantothercharges/getcharges`, { tenant_id: id }, configheader)
    //         .then((response) => {
    //             setCharges(response.data)
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // }, [])

    const handleEditClick = () => {
        navigate(`${process.env.PUBLIC_URL}/admin/tenant/tenantedit/${id}`);
    }

    const deliveryPartnerNames = deliveryPartners.length > 0
        ? deliveryPartners.map(partner => partner.delivery_partner_id.delivery_partner_name).join(', ')
        : '';

    const chargeNames = charges.length > 0
        ? charges.map(charge => charge.display_name).join(', ')
        : '';

    return (
        <Row>
            <Col xl='4'>
                <Fragment>
                    <Card>
                        <CardHeader>
                            <H4 attrH4={{ className: 'card-title mb-0' }}>{tenant.tenant_store_name}</H4>
                            <div className='card-options'>
                                <a className='card-options-collapse' href='#javascript'>
                                    <i className='fe fe-chevron-up'></i>
                                </a>
                                <a className='card-options-remove' href='#javascript'>
                                    <i className='fe fe-x'></i>
                                </a>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <Row className='mb-2'>
                                    <div className='profile-title'>
                                        <div className='media'>
                                            <Image attrImage={{ className: 'img-70 m-0 rounded-circle', alt: '', src: `${require('../../../../assets/images/img001.jpg')}` }} />

                                        </div>
                                    </div>
                                </Row>
                                <Row>
                                    <Row >
                                        <FormGroup className="mb-3"> <Label className="form-label">Brand Name</Label>
                                            <Input className="form-control" type="text" disabled="true" defaultValue={tenant.tenant_brand_name} />
                                        </FormGroup>
                                    </Row>
                                    <Row >
                                        <FormGroup className="mb-3"> <Label className="form-label">Concessionaire Name</Label>
                                            <Input className="form-control" type="text" disabled="true" defaultValue={concessionaire_name} />
                                        </FormGroup>
                                    </Row>
                                    <Row >
                                        <FormGroup className="mb-3"> <Label className="form-label">Concessionaire Code</Label>
                                            <Input className="form-control" type="text" disabled="true" defaultValue={concessionaire_code} />
                                        </FormGroup>
                                    </Row>
                                    <Row >
                                        <FormGroup> <Label className="form-label">Country</Label>
                                            <Input className="form-control" type="text" disabled="true" defaultValue={country_name} />
                                        </FormGroup>
                                    </Row>
                                    <Row >
                                        <FormGroup className="mb-3"> <Label className="form-label">Property</Label>
                                            <Input className="form-control" type="text" disabled="true" defaultValue={property_name} />
                                        </FormGroup>
                                    </Row>
                                    <Row >
                                        <FormGroup className="mb-3"> <Label className="form-label">Mall code</Label>
                                            <Input className="form-control" type="text" disabled="true" defaultValue={property_mall_code} />
                                        </FormGroup>
                                    </Row>
                                    <Row >
                                        <FormGroup className="mb-3"> <Label className="form-label">Category</Label>
                                            <Input className="form-control" type="text" disabled="true" defaultValue={category_name} />
                                        </FormGroup>
                                    </Row>
                                    <Row >
                                        <FormGroup className="mb-3"> <Label className="form-label">Subcategory</Label>
                                            <Input className="form-control" type="text" disabled="true" defaultValue={subcategory_name} />
                                        </FormGroup>
                                    </Row>
                                    <Row >
                                        <FormGroup className="mb-3"> <Label className="form-label">Group Name</Label>
                                            <Input className="form-control" type="text" disabled="true" defaultValue={tenant.tenant_group_name} />
                                        </FormGroup>
                                    </Row>
                                    <Row>
                                        <FormGroup className="mb-3"> <Label className="form-label">Franchisee</Label>
                                            <Input className="form-control" type="text" disabled="true" defaultValue={tenant.tenant_franchisee ? 'Yes' : 'No'} />
                                        </FormGroup>
                                    </Row>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Fragment>
            </Col>
            <Col xl='8'>
                <Fragment>
                    <Form className="card" >
                        <CardHeader>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                <H4 attrH4={{ className: "card-title mb-0" }}>Tenant Details</H4>
                                {(manageTenant === "1" || manageGeneralSettings === "1") && (
                                    <Btn attrBtn={{ color: "primary", type: "submit", marginLeft: '10px', onClick: () => handleEditClick() }}  >Edit</Btn>
                                )}
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Email</Label>
                                        <Input className="form-control" type="text" disabled="true" defaultValue={tenant.tenant_email_id} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Address</Label>
                                        <Input className="form-control" type="text" disabled="true" defaultValue={tenant.tenant_address} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Tenant Code</Label>
                                        <Input className="form-control" type="text" disabled="true" defaultValue={tenant.tenant_code} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Unit Code</Label>
                                        <Input className="form-control" type="text" disabled="true" defaultValue={tenant.tenant_unit_code} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Store Type</Label>
                                        <Input className="form-control" type="text" disabled="true" defaultValue={tenant.tenant_store_type} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Current Status</Label>
                                        <Input className="form-control" type="text" disabled="true" defaultValue={tenant.tenant_current_status} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Carpet Area</Label>
                                        <Input className="form-control" type="text" disabled="true" defaultValue={tenant.tenant_carpet_area} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Chargable Area</Label>
                                        <Input className="form-control" type="text" disabled="true" defaultValue={tenant.tenant_chargable_area} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Floor</Label>
                                        <Input className="form-control" type="text" disabled="true" defaultValue={tenant.tenant_floor} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Location</Label>
                                        <Input className="form-control" type="text" disabled="true" defaultValue={tenant.tenant_location} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Lease Start Date</Label>
                                        <Input className="form-control" type="text" disabled="true" value={tenant_lease_startdate} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Lease End Date</Label>
                                        <Input className="form-control" type="text" disabled="true" value={tenant_lease_enddate} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Base Rent</Label>
                                        <Input className="form-control" type="text" disabled="true" defaultValue={tenant.tenant_base_rent} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Revenue Share %</Label>
                                        <Input className="form-control" type="text" disabled="true" defaultValue={tenant.tenant_revenue_share} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Revenue Share Method</Label>
                                        <Input className="form-control" type="text" disabled="true" defaultValue={revenue_method} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Contact Person</Label>
                                        <Input className="form-control" type="text" disabled="true" defaultValue={tenant.tenant_contact_person} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Contact Number</Label>
                                        <Input className="form-control" type="text" disabled="true" defaultValue={tenant.tenant_contact_number} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">IT Contact Person</Label>
                                        <Input className="form-control" type="text" disabled="true" defaultValue={tenant.tenant_it_contact_person} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">IT Contact Number</Label>
                                        <Input className="form-control" type="text" disabled="true" defaultValue={tenant.tenant_it_contact_number} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">POS Name</Label>
                                        <Input className="form-control" type="text" disabled="true" defaultValue={tenant.tenant_pos_name} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Data Capture Method</Label>
                                        <Input className="form-control" type="text" disabled="true" defaultValue={tenant.tenant_data_capture_method} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Data Transfer Method</Label>
                                        <Input className="form-control" type="text" disabled="true" defaultValue={tenant.tenant_mode_of_transfer} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Created Date</Label>
                                        <Input className="form-control" type="text" disabled="true" defaultValue={tenant_created_date} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Created By</Label>
                                        <Input className="form-control" type="text" disabled="true" value={tenant.tenant_created_by?.userFirstName} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Modified Date</Label>
                                        <Input className="form-control" type="text" disabled="true" defaultValue={tenant_modified_date} />
                                    </FormGroup>
                                </Col>
                                {deliveryPartners.length > 0 && (
                                    <Col md="6">
                                        <FormGroup className="mb-3"> <Label className="form-label">Delivery Partners</Label>
                                            <Input className="form-control" type="textarea" disabled="true" value={deliveryPartnerNames} rows="3" />
                                        </FormGroup>
                                    </Col>
                                )}

                                {
                                    charges.map((charge) => (
                                        <Col md="6" key={charge.other_charge_id}>
                                            <FormGroup className="mb-3">
                                                <Label htmlFor={`charge_${charge.display_name}`}>{charge.display_name}</Label>
                                                <input
                                                    className={`form-control ${errors[`charge_${charge.display_name}`] && "is-invalid"}`}
                                                    id={`charge_${charge.display_name}`}
                                                    type="number"
                                                    disabled
                                                    name={`charge_${charge.display_name}`}
                                                    defaultValue={
                                                        charge.charge_name === 'charge1' ? tenant.tenant_charge1 :
                                                            charge.charge_name === 'charge2' ? tenant.tenant_charge2 :
                                                                charge.charge_name === 'charge3' ? tenant.tenant_charge3 :
                                                                    charge.charge_name === 'charge4' ? tenant.tenant_charge4 :
                                                                        charge.charge_name === 'charge5' ? tenant.tenant_charge5 :
                                                                            ''
                                                    }
                                                />
                                                <span className="text-danger">
                                                    {errors[`charge_${charge.display_name}`] && `${charge.display_name} is required`}
                                                </span>
                                            </FormGroup>
                                        </Col>
                                    ))
                                }

                                <Col md="6">
                                    <FormGroup className="mb-3"> <Label className="form-label">Disable</Label>
                                        <Input className="form-control" type="text" disabled="true" value={tenant.tenant_disable ? 'Yes' : 'No'} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Form>
                </Fragment>
            </Col>
        </Row>
    )
}

export default TenantDtl