import React, { Fragment, useState, useEffect } from 'react';
import { Btn, H4, Spinner } from '../../../../AbstractElements';
import { Card, CardBody, Col, Table, Form, FormGroup, Label, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, CardHeader } from 'reactstrap';
import axios from 'axios';
import config from '../../../../config';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import { exportToExcel, exportToPDF } from '../ExportReport';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const DailyComprehensiveRevenue = () => {

    if (localStorage.getItem("accessReports") !== "1") {
        alert('You are not authorized to view this page');
        window.history.back(); // Navigate back to the previous URL
    }

    const currentYear = new Date().getFullYear();
    const concessionaire_id = localStorage.getItem("concessionaireId");
    const property_access = localStorage.getItem("propertyAccess");
    const [propertyAccessNames, setPropertyAccesssNames] = useState('');
    const login_name = localStorage.getItem("loginName");
    const login_id = localStorage.getItem("loginId");
    const [properties, setProperties] = useState([]);
    const [categories, setCategories] = useState([]);
    const [allSubcategories, setAllSubcategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [groups, setGroups] = useState([]);
    const [allTenants, setAllTenants] = useState([]);
    const [tenants, setTenants] = useState([]);
    const [allFloors, setAllFloors] = useState([]);
    const [floors, setFloors] = useState([]);
    const [property_id, setPropertyId] = useState('');
    const [category_id, setCategoryId] = useState('');
    const [subcategory_id, setSubcategoryId] = useState('');
    const [group_name, setGroupName] = useState('');
    const [tenant_id, setTenantId] = useState('');
    const [selectedFloor, setSelectedFloor] = useState('');
    const [reportData, setReportData] = useState([]);
    const [propertywiseData, setPropertyWiseData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage] = useState(50);
    const [loading, setLoading] = useState(false)
    const [tooltip, setTooltip] = useState('');

    const handleMouseEnter = (text) => setTooltip(text);
    const handleMouseLeave = () => setTooltip('');

    const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    useEffect(() => {
        axios
            .post(`${config.hostname}/property/getpropertybyconcessionaire`, { property_concessionaire_id: concessionaire_id, property_id: property_access }, configheader)
            .then((response) => {
                setProperties(response.data);
                const propertyNames = response.data.map(property => property.property_name).join(',');
                setPropertyAccesssNames(propertyNames);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios
            .post(`${config.hostname}/category/getcategorybyconcessionaire`, { category_concessionaire_id: concessionaire_id }, configheader)
            .then((response) => {
                setCategories(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);


    useEffect(() => {
        axios
            .post(`${config.hostname}/subcategory/getsubcategorybyconcessionaire`, { subcategory_concessionaire_id: concessionaire_id }, configheader)
            .then((response) => {
                setAllSubcategories(response.data);
                setSubcategories(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        let filteredSubcategories = allSubcategories;

        if (category_id !== '') {
            filteredSubcategories = filteredSubcategories.filter(subcategory => subcategory.category_id._id === category_id);
        }

        setSubcategories(filteredSubcategories);
    }, [category_id]);

    useEffect(() => {
        axios
            .post(`${config.hostname}/tenant/getgroupnames`, { tenant_concessionaire_id: concessionaire_id, property_id: property_access }, configheader)
            .then((response) => {
                setGroups(response.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios
            .post(`${config.hostname}/tenant/getenantsbyconcessionaire`, { tenant_concessionaire_id: concessionaire_id, property_id: property_access }, configheader)
            .then((response) => {
                setAllTenants(response.data);
                setTenants(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        let filteredTenants = allTenants;

        if (group_name !== '') {
            filteredTenants = filteredTenants.filter(tenant => tenant.tenant_group_name === group_name);
        }

        if (category_id !== '') {
            filteredTenants = filteredTenants.filter(tenant => tenant.tenant_category_id._id === category_id);
        }

        if (property_id !== '') {
            filteredTenants = filteredTenants.filter(tenant => tenant.tenant_property_id._id === property_id);
        }

        if (subcategory_id !== '') {
            filteredTenants = filteredTenants.filter(tenant => tenant.tenant_subcategory_id._id === subcategory_id);
        }

        setTenants(filteredTenants);
    }, [group_name, property_id, category_id, subcategory_id, allTenants]);

    useEffect(() => {
        axios
            .post(`${config.hostname}/property/getfloorsbyconcessionaire`, { concessionaire_id, property_id: property_access }, configheader)
            .then((response) => {
                setAllFloors(response.data);
                setFloors(getUniqueFloors(response.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        let filteredFloors = allFloors;

        if (property_id !== '') {
            filteredFloors = filteredFloors.filter(floor => floor.property_id === property_id);
        }

        setFloors(getUniqueFloors(filteredFloors));
    }, [property_id]);

    const getUniqueFloors = (floors) => {
        const floorNames = new Set();
        return floors.filter(floor => {
            if (!floorNames.has(floor.floor_id.floor_name)) {
                floorNames.add(floor.floor_id.floor_name);
                return true;
            }
            return false;
        });
    };

    const handlePropertyChange = (e) => {
        setPropertyId(e.target.value);
        setTenantId('');
        setSelectedFloor('');
        setReportData([]);
        setPropertyWiseData([]);
        setCurrentPage(0);
    };

    const handleCategoryChange = (e) => {
        setCategoryId(e.target.value);
        setSubcategoryId('')
        setTenantId('');
        setReportData([]);
        setPropertyWiseData([]);
        setCurrentPage(0);
    };

    const handleSubCategoryChange = (e) => {
        setSubcategoryId(e.target.value);
        setTenantId('');
        setReportData([]);
        setPropertyWiseData([]);
        setCurrentPage(0);
    };

    const handleGroupChange = (e) => {
        setGroupName(e.target.value);
        setTenantId('');
        setReportData([]);
        setPropertyWiseData([]);
        setCurrentPage(0);
    };

    const handleTenantChange = (e) => {
        setTenantId(e.target.value);
        setReportData([]);
        setPropertyWiseData([]);
        setCurrentPage(0);
    };

    const handleFloorChange = (e) => {
        setSelectedFloor(e.target.value);
        setReportData([]);
        setPropertyWiseData([]);
        setCurrentPage(0);
    };

    const resetFilters = () => {
        setPropertyId('');
        setSelectedFloor('');
        setCategoryId('');
        setSubcategoryId('');
        setGroupName('');
        setTenantId('');
        setReportData([]);
        setPropertyWiseData([]);
        setCurrentPage(0);
    };

    const generateReport = async (e) => {
        e.preventDefault();


        const category_name = category_id !== '' ? categories.find(category => category._id === category_id).category_name : '';
        const property_name = property_id !== '' ? properties.find(property => property._id === property_id).property_name : '';
        const subcategory_name = subcategory_id !== '' ? subcategories.find(subcategory => subcategory._id === subcategory_id).subcategory_name : '';
        const tenant_name = tenant_id !== '' ? tenants.find(tenant => tenant._id === tenant_id).tenant_store_name : '';

        const data = {
            concessionaire_id,
            property_access: propertyAccessNames,
            property_name,
            category_name,
            subcategory_name,
            group_name,
            tenant_name,
            floor: selectedFloor
        };

        setLoading(true)
        axios
            .post(`${config.hostname}/reports/getdailycomprehensivereport`, data, configheader)
            .then((response) => {
                setReportData(response.data);
                setLoading(false)
                console.log(response.data);
                insertToLog(" generated daily comprehensive revenuue report")
            })
            .catch((error) => {
                toast.error('Error');
                setLoading(false)
                console.log(error);
            });

        axios
            .post(`${config.hostname}/reports/getdailycomprehensivebyproperty`, data, configheader)
            .then((response) => {
                setPropertyWiseData(response.data);
                setLoading(false)
                console.log(response.data);
                // insertToLog(" generated daily comprehensive revenuue report")
            })
            .catch((error) => {
                toast.error('Error');
                setLoading(false)
                console.log(error);
            });
    };

    const insertToLog = (task) => {
        const data = {
            created_by: login_id,
            concessionaire_id: concessionaire_id,
            module: "Reports",
            task: login_name + task
        }
        axios
            .post(`${config.hostname}/log`, data, configheader)
            .then(() => {
                console.log("inserted to log table")
            })
            .catch((error) => {
                toast.error('Error')
                console.log(error);

            });
    }


    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    const offset = currentPage * itemsPerPage;
    //const currentPageData = reportData.slice(offset, offset + itemsPerPage);
    const pageCount = Math.ceil(reportData.length / itemsPerPage);

    const getStyledValue = (value) => {
        const numericValue = parseFloat(value);
        let style = {};
        let arrow = '';

        if (numericValue > 0) {
            style = { color: 'green' };
            arrow = '↑';
        } else if (numericValue < 0) {
            style = { color: 'red' };
            arrow = '↓';
        }

        return (
            <span style={style}>
                {numericValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}% {arrow}
            </span>
        );
    };


    const handleExport = (format) => {
        // if (reportData.length > 0) {
        const metadata = [
            ['Property', property_id ? properties.find(p => p._id === property_id)?.property_name : 'All'],
            ['Category', category_id ? categories.find(s => s._id === category_id)?.category_name : 'All'],
            ['Subcategory', subcategory_id ? subcategories.find(s => s._id === subcategory_id)?.subcategory_name : 'All'],
            ['Group', group_name ? group_name : 'All'],
            ['Tenant', tenant_id ? tenants.find(t => t._id === tenant_id)?.tenant_store_name : 'All'],
            ['Floor', selectedFloor || 'All']
        ];

        if (format === 'excel') {
            console.log('export to excel')
            exportToExcel(reportData, propertywiseData, metadata);
            insertToLog(" exported daily comprehensive report to excel")
        } else if (format === 'pdf') {
            exportToPDF(reportData, propertywiseData, metadata);
            insertToLog(" exported daily comprehensive report to pdf")
        }
        // }
    };



    const exportToExcel = (reportData, propertywiseData, metadata) => {
        const workbook = XLSX.utils.book_new();

        const sheetData = [];

        // Add Metadata
        metadata.forEach(([key, value]) => {
            sheetData.push([key, value]);
        });
        sheetData.push([]); // Add empty row after metadata


        if (reportData) {
            sheetData.push(['Comparison']);
            sheetData.push(['Date', 'Sales', 'Traffic', 'Revenue', 'Conversion', 'ATV']);
            sheetData.push([
                'Yesterday',
                Number(reportData.yesterday.sales).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                0,
                Number(reportData.yesterday.revenueShare).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                0.0,
                Number(reportData.yesterday.atv).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            ]);
            sheetData.push([
                'Last Week',
                Number(reportData.lastWeek.sales).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                0,
                Number(reportData.lastWeek.revenueShare).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                0.0,
                Number(reportData.lastWeek.atv).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            ]);
            sheetData.push([
                'Last Year',
                Number(reportData.lastYear.sales).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                0,
                Number(reportData.lastYear.revenueShare).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                0.0,
                Number(reportData.lastYear.atv).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            ]);
            sheetData.push([
                'Yesterday vs LW',
                Number(reportData.growthYesterdayVsLastWeek.sales).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                0,
                Number(reportData.growthYesterdayVsLastWeek.revenueShare).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                0.0,
                Number(reportData.growthYesterdayVsLastWeek.atv).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            ]);
            sheetData.push([
                'Yesterday vs LY',
                Number(reportData.growthYesterdayVsLastYear.sales).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                0,
                Number(reportData.growthYesterdayVsLastYear.revenueShare).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                0.0,
                Number(reportData.growthYesterdayVsLastYear.atv).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            ]);
            sheetData.push([]); // Empty row after main report data
        }


        // Add Property-wise Data
        // Object.entries(propertywiseData).forEach(([propertyName, propertyData]) => {
        //     sheetData.push([propertyName]);
        //     sheetData.push(['Date', 'Sales', 'Traffic', 'Revenue', 'Conversion', 'ATV']);
        //     sheetData.push(['Yesterday', propertyData.yesterday?.total_sales || 0, 0, propertyData.yesterday?.total_revenue_share || 0, 0.0, propertyData.yesterday?.atv || 0]);
        //     sheetData.push(['Last Week', propertyData.lastWeek?.total_sales || 0, 0, propertyData.lastWeek?.total_revenue_share || 0, 0.0, propertyData.lastWeek?.atv || 0]);
        //     sheetData.push(['Last Year', propertyData.lastYear?.total_sales || 0, 0, propertyData.lastYear?.total_revenue_share || 0, 0.0, propertyData.lastYear?.atv || 0]);
        //     sheetData.push(['Yesterday vs LW', propertyData.growthYesterdayVsLastWeek?.sales || 0, 0, propertyData.growthYesterdayVsLastWeek?.revenueShare || 0, 0.0, propertyData.growthYesterdayVsLastWeek?.atv || 0]);
        //     sheetData.push(['Yesterday vs LY', propertyData.growthYesterdayVsLastYear?.sales || 0, 0, propertyData.growthYesterdayVsLastYear?.revenueShare || 0, 0.0, propertyData.growthYesterdayVsLastYear?.atv || 0]);
        //     sheetData.push([]); // Empty row after each property data
        // });
        Object.entries(propertywiseData).forEach(([propertyName, propertyData]) => {
            sheetData.push([propertyName]);
            sheetData.push(['Date', 'Sales', 'Traffic', 'Revenue', 'Conversion', 'ATV']);
            sheetData.push([
                'Yesterday',
                Number(propertyData.yesterday?.total_sales || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                0,
                Number(propertyData.yesterday?.total_revenue_share || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                0.0,
                Number(propertyData.yesterday?.atv || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            ]);
            sheetData.push([
                'Last Week',
                Number(propertyData.lastWeek?.total_sales || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                0,
                Number(propertyData.lastWeek?.total_revenue_share || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                0.0,
                Number(propertyData.lastWeek?.atv || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            ]);
            sheetData.push([
                'Last Year',
                Number(propertyData.lastYear?.total_sales || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                0,
                Number(propertyData.lastYear?.total_revenue_share || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                0.0,
                Number(propertyData.lastYear?.atv || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            ]);
            sheetData.push([
                'Yesterday vs LW',
                Number(propertyData.growthYesterdayVsLastWeek?.sales || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                0,
                Number(propertyData.growthYesterdayVsLastWeek?.revenueShare || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                0.0,
                Number(propertyData.growthYesterdayVsLastWeek?.atv || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            ]);
            sheetData.push([
                'Yesterday vs LY',
                Number(propertyData.growthYesterdayVsLastYear?.sales || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                0,
                Number(propertyData.growthYesterdayVsLastYear?.revenueShare || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                0.0,
                Number(propertyData.growthYesterdayVsLastYear?.atv || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            ]);
            sheetData.push([]); // Empty row after each property data
        });


        // Convert to worksheet and export
        const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
        XLSX.writeFile(workbook, 'DailyComprehensive.xlsx');
    };



    const reset = () => {
        setPropertyId('');
        setCategoryId('');
        setSubcategoryId('');
        setGroupName('');
        setTenantId('');
        setSelectedFloor('');
        setReportData([]);
        setPropertyWiseData([]);
    }


    const exportToPDF = (reportData, propertywiseData, metadata) => {
        const doc = new jsPDF();

        // Add Metadata
        doc.setFontSize(12);
        metadata.forEach(([key, value], index) => {
            doc.text(`${key}: ${value}`, 10, 10 + index * 7);
        });

        let startY = 10 + metadata.length * 7 + 10; // Start position after metadata

        // Add Main Report Data
        if (reportData) {
            doc.text('Comparison', 10, startY);
            startY += 10;

            doc.autoTable({
                head: [['Date', 'Sales', 'Traffic', 'Revenue', 'Conversion', 'ATV']],
                // body: [
                //     ['Yesterday', reportData.yesterday.sales, 0, reportData.yesterday.revenueShare, '0.00', reportData.yesterday.atv],
                //     ['Last Week', reportData.lastWeek.sales, 0, reportData.lastWeek.revenueShare, '0.00', reportData.lastWeek.atv],
                //     ['Last Year', reportData.lastYear.sales, 0, reportData.lastYear.revenueShare, '0.00', reportData.lastYear.atv],
                //     ['Yesterday vs LW', reportData.growthYesterdayVsLastWeek.sales, 0, reportData.growthYesterdayVsLastWeek.revenueShare, '0.00', reportData.growthYesterdayVsLastWeek.atv],
                //     ['Yesterday vs LY', reportData.growthYesterdayVsLastYear.sales, 0, reportData.growthYesterdayVsLastYear.revenueShare, '0.00', reportData.growthYesterdayVsLastYear.atv],
                // ],
                body: [
                    [
                        'Yesterday',
                        Number(reportData.yesterday.sales).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        0,
                        Number(reportData.yesterday.revenueShare).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        '0.00',
                        Number(reportData.yesterday.atv).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    ],
                    [
                        'Last Week',
                        Number(reportData.lastWeek.sales).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        0,
                        Number(reportData.lastWeek.revenueShare).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        '0.00',
                        Number(reportData.lastWeek.atv).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    ],
                    [
                        'Last Year',
                        Number(reportData.lastYear.sales).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        0,
                        Number(reportData.lastYear.revenueShare).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        '0.00',
                        Number(reportData.lastYear.atv).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    ],
                    [
                        'Yesterday vs LW',
                        Number(reportData.growthYesterdayVsLastWeek.sales).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        0,
                        Number(reportData.growthYesterdayVsLastWeek.revenueShare).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        '0.00',
                        Number(reportData.growthYesterdayVsLastWeek.atv).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    ],
                    [
                        'Yesterday vs LY',
                        Number(reportData.growthYesterdayVsLastYear.sales).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        0,
                        Number(reportData.growthYesterdayVsLastYear.revenueShare).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        '0.00',
                        Number(reportData.growthYesterdayVsLastYear.atv).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    ],
                ],

                startY: startY,
            });

            startY = doc.lastAutoTable.finalY + 10; // Adjust Y position for next table
        }

        // Add Property-wise Data
        Object.entries(propertywiseData).forEach(([propertyName, propertyData]) => {
            doc.text(propertyName, 10, startY);
            startY += 10;

            doc.autoTable({
                head: [['Date', 'Sales', 'Traffic', 'Revenue', 'Conversion', 'ATV']],
                // body: [
                //     ['Yesterday', propertyData.yesterday?.total_sales || 0, 0, propertyData.yesterday?.total_revenue_share || 0, '0.00', propertyData.yesterday?.atv || 0],
                //     ['Last Week', propertyData.lastWeek?.total_sales || 0, 0, propertyData.lastWeek?.total_revenue_share || 0, '0.00', propertyData.lastWeek?.atv || 0],
                //     ['Last Year', propertyData.lastYear?.total_sales || 0, 0, propertyData.lastYear?.total_revenue_share || 0, '0.00', propertyData.lastYear?.atv || 0],
                //     ['Yesterday vs LW', propertyData.growthYesterdayVsLastWeek?.sales || 0, 0, propertyData.growthYesterdayVsLastWeek?.revenueShare || 0, '0.00', propertyData.growthYesterdayVsLastWeek?.atv || 0],
                //     ['Yesterday vs LY', propertyData.growthYesterdayVsLastYear?.sales || 0, 0, propertyData.growthYesterdayVsLastYear?.revenueShare || 0, '0.00', propertyData.growthYesterdayVsLastYear?.atv || 0],
                // ],
                body: [
                    [
                        'Yesterday',
                        Number(propertyData.yesterday?.total_sales || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        0,
                        Number(propertyData.yesterday?.total_revenue_share || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        '0.00',
                        Number(propertyData.yesterday?.atv || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    ],
                    [
                        'Last Week',
                        Number(propertyData.lastWeek?.total_sales || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        0,
                        Number(propertyData.lastWeek?.total_revenue_share || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        '0.00',
                        Number(propertyData.lastWeek?.atv || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    ],
                    [
                        'Last Year',
                        Number(propertyData.lastYear?.total_sales || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        0,
                        Number(propertyData.lastYear?.total_revenue_share || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        '0.00',
                        Number(propertyData.lastYear?.atv || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    ],
                    [
                        'Yesterday vs LW',
                        Number(propertyData.growthYesterdayVsLastWeek?.sales || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        0,
                        Number(propertyData.growthYesterdayVsLastWeek?.revenueShare || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        '0.00',
                        Number(propertyData.growthYesterdayVsLastWeek?.atv || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    ],
                    [
                        'Yesterday vs LY',
                        Number(propertyData.growthYesterdayVsLastYear?.sales || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        0,
                        Number(propertyData.growthYesterdayVsLastYear?.revenueShare || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                        '0.00',
                        Number(propertyData.growthYesterdayVsLastYear?.atv || 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
                    ],
                ],

                startY: startY,
            });

            startY = doc.lastAutoTable.finalY + 10; // Adjust Y position for next table
        });

        // Save PDF
        doc.save('DailyComprehensive.pdf');
    };



    return (
        <Fragment>
            <Form className='theme-form' onSubmit={generateReport}>
                <Card>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="property_id">Property</Label>
                                    <select name="property_id" id="property_id" className="form-control"
                                        value={property_id} onChange={handlePropertyChange}>
                                        <option value="">All</option>
                                        {properties.map(property => (
                                            <option key={property._id} value={property._id}>{property.property_name}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="category_id">Category</Label>
                                    <select name="category_id" id="category_id" className="form-control"
                                        value={category_id} onChange={handleCategoryChange}>
                                        <option value="">All</option>
                                        {categories.map(category => (
                                            <option key={category._id} value={category._id}>{category.category_name}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="subcategory_id">Sub Category</Label>
                                    <select name="subcategory_id" id="subcategory_id" className="form-control"
                                        value={subcategory_id} onChange={handleSubCategoryChange}>
                                        <option value="">All</option>
                                        {subcategories.map(subcategory => (
                                            <option key={subcategory._id} value={subcategory._id}>{subcategory.subcategory_name}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="subcategory_id">Group</Label>
                                    <select name="subcategory_id" id="subcategory_id" className="form-control"
                                        value={group_name} onChange={handleGroupChange}>
                                        <option value="">All</option>
                                        {groups.map((group, index) => (
                                            <option key={index} value={group}>{group}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_id">Tenant</Label>
                                    <select name="tenant_id" id="tenant_id" className="form-control"
                                        value={tenant_id} onChange={handleTenantChange}>
                                        <option value="">All</option>
                                        {tenants.map(tenant => (
                                            <option key={tenant._id} value={tenant._id}>{tenant.tenant_store_name}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_id">Floor</Label>
                                    <select name="tenant_id" id="tenant_id" className="form-control"
                                        value={selectedFloor} onChange={handleFloorChange}>
                                        <option value="">All</option>
                                        {floors.map((floor, index) => (
                                            <option key={index} value={floor.floor_id.floor_name}>{floor.floor_id.floor_name}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col >
                                <Btn attrBtn={{ color: 'primary', className: 'me-3', type: "submit" }} >Generate Report</Btn>
                                <Btn attrBtn={{ color: 'primary', className: 'me-3', onClick: reset }} >Reset</Btn>
                            </Col>

                        </Row>


                    </CardBody>
                </Card>
            </Form>
            {loading === true ? (
                <div className="loader-box">
                    <Spinner attrSpinner={{ className: 'loader-3' }} />
                </div>

            ) : (
                <Fragment>
                    <Card>
                        <CardHeader>
                            <Row className="justify-content-end">
                                <Col xs="auto" style={{ position: 'relative' }}>
                                    <Btn
                                        attrBtn={{
                                            color: 'primary',
                                            type: 'button',
                                            onClick: () => handleExport('excel'),
                                            onMouseEnter: () => handleMouseEnter('Export to Excel'),
                                            onMouseLeave: handleMouseLeave
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faFileExcel} size="lg" />
                                    </Btn>
                                    {tooltip === 'Export to Excel' && (
                                        <div style={{
                                            position: 'absolute',
                                            top: '-30px',
                                            left: '50%',
                                            transform: 'translateX(-50%)',
                                            backgroundColor: 'black',
                                            color: 'white',
                                            padding: '5px 10px',
                                            borderRadius: '3px',
                                            whiteSpace: 'nowrap',
                                            fontSize: '12px',
                                            zIndex: 1,
                                        }}>
                                            {tooltip}
                                        </div>
                                    )}
                                </Col>
                                <Col xs="auto" style={{ position: 'relative' }}>
                                    <Btn
                                        attrBtn={{
                                            color: 'primary',
                                            type: 'button',
                                            onClick: () => handleExport('pdf'),
                                            onMouseEnter: () => handleMouseEnter('Export to PDF'),
                                            onMouseLeave: handleMouseLeave
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faFilePdf} size="lg" />
                                    </Btn>
                                    {tooltip === 'Export to PDF' && (
                                        <div style={{
                                            position: 'absolute',
                                            top: '-30px',
                                            left: '50%',
                                            transform: 'translateX(-50%)',
                                            backgroundColor: 'black',
                                            color: 'white',
                                            padding: '5px 10px',
                                            borderRadius: '3px',
                                            whiteSpace: 'nowrap',
                                            fontSize: '12px',
                                            zIndex: 1,
                                        }}>
                                            {tooltip}
                                        </div>
                                    )}
                                </Col>
                            </Row>
                            <H4 attrH4={{ className: 'card-title mb-0', style: { fontSize: '17px' } }}>Comparison</H4>
                        </CardHeader>
                        <CardBody>
                            <Col sm="12" lg="12" xl="12">
                                <div className="table-responsive">
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th scope="col" >Date</th>
                                                <th scope="col">Sales</th>
                                                <th scope="col" >Traffic</th>
                                                <th scope="col" >Revenue</th>
                                                <th scope="col" >Conversion</th>
                                                <th scope="col" >ATV</th>
                                            </tr>
                                        </thead>
                                        {reportData && (
                                            <tbody>
                                                {reportData.yesterday && (
                                                    <tr>
                                                        <td>Yesterday</td>
                                                        <td>{(reportData.yesterday.sales).toLocaleString()}</td>
                                                        <td>0</td>
                                                        <td>{(reportData.yesterday.revenueShare).toLocaleString()}</td>
                                                        <td>0.00</td>
                                                        <td>{(reportData.yesterday.atv).toFixed(2)}</td>
                                                    </tr>
                                                )}
                                                {reportData.lastWeek && (
                                                    <tr>
                                                        <td>Last Week</td>
                                                        <td>{(reportData.lastWeek.sales).toLocaleString()}</td>
                                                        <td>0</td>
                                                        <td>{(reportData.lastWeek.revenueShare).toLocaleString()}</td>
                                                        <td>0.00</td>
                                                        <td>{(reportData.lastWeek.atv).toFixed(2)}</td>
                                                    </tr>
                                                )}
                                                {reportData.lastYear && (
                                                    <tr>
                                                        <td>Last Year</td>
                                                        <td>{(reportData.lastYear.sales).toLocaleString()}</td>
                                                        <td>0</td>
                                                        <td>{(reportData.lastYear.revenueShare).toLocaleString()}</td>
                                                        <td>0.00</td>
                                                        <td>{(reportData.lastYear.atv).toFixed(2)}</td>
                                                    </tr>
                                                )}
                                                {reportData.growthYesterdayVsLastWeek && (
                                                    <tr>
                                                        <td>Yesterday vs LW</td>
                                                        <td>{getStyledValue(reportData.growthYesterdayVsLastWeek.sales)}</td>
                                                        <td>0</td>
                                                        <td>{getStyledValue(reportData.growthYesterdayVsLastWeek.revenueShare)}</td>
                                                        <td>0.00</td>
                                                        <td>{getStyledValue(reportData.growthYesterdayVsLastWeek.atv)}</td>
                                                    </tr>
                                                )}
                                                {reportData.growthYesterdayVsLastYear && (
                                                    <tr>
                                                        <td>Yesterday vs LY</td>
                                                        <td>{getStyledValue(reportData.growthYesterdayVsLastYear.sales)}</td>
                                                        <td>0</td>
                                                        <td>{getStyledValue(reportData.growthYesterdayVsLastYear.revenueShare)}</td>
                                                        <td>0.00</td>
                                                        <td>{getStyledValue(reportData.growthYesterdayVsLastYear.atv)}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        )}
                                    </Table>
                                </div>

                            </Col>
                        </CardBody>
                    </Card>

                    <div>
                        {Object.entries(propertywiseData).map(([propertyName, propertyData]) => (
                            <Card key={propertyName}>
                                <CardHeader>
                                    <H4 attrH4={{ className: 'card-title mb-0', style: { fontSize: '17px' } }}>{propertyName}</H4>
                                </CardHeader>
                                <CardBody>
                                    <Col sm="12" lg="12" xl="12">
                                        <div className="table-responsive">
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Date</th>
                                                        <th scope="col">Sales</th>
                                                        <th scope="col">Traffic</th>
                                                        <th scope="col">Revenue</th>
                                                        <th scope="col">Conversion</th>
                                                        <th scope="col">ATV</th>
                                                    </tr>
                                                </thead>
                                                {propertyData && (
                                                    <tbody>
                                                        {propertyData.yesterday && (
                                                            <tr>
                                                                <td>Yesterday</td>
                                                                <td>{propertyData.yesterday.total_sales ? (propertyData.yesterday.total_sales).toLocaleString() : 0}</td>
                                                                <td>0</td>
                                                                <td>{propertyData.yesterday.total_revenue_share ? (propertyData.yesterday.total_revenue_share).toLocaleString() : 0}</td>
                                                                <td>0.00</td>
                                                                <td>{propertyData.yesterday.atv ? (propertyData.yesterday.atv).toFixed(2) : 0}</td>
                                                            </tr>
                                                        )}
                                                        {propertyData.lastWeek && (
                                                            <tr>
                                                                <td>Last Week</td>
                                                                <td>{propertyData.lastWeek.total_sales ? (propertyData.lastWeek.total_sales).toLocaleString() : 0}</td>
                                                                <td>0</td>
                                                                <td>{propertyData.lastWeek.total_revenue_share ? (propertyData.lastWeek.total_revenue_share).toLocaleString() : 0}</td>
                                                                <td>0.00</td>
                                                                <td>{propertyData.lastWeek.atv ? (propertyData.lastWeek.atv).toFixed(2) : 0}</td>
                                                            </tr>
                                                        )}
                                                        {propertyData.lastYear && (
                                                            <tr>
                                                                <td>Last Year</td>
                                                                <td>{propertyData.lastYear.total_sales ? (propertyData.lastYear.total_sales).toLocaleString() : 0}</td>
                                                                <td>0</td>
                                                                <td>{propertyData.lastYear.total_revenue_share ? (propertyData.lastYear.total_revenue_share).toLocaleString() : 0}</td>
                                                                <td>0.00</td>
                                                                <td>{propertyData.lastYear.atv ? (propertyData.lastYear.atv).toFixed(2) : 0}</td>
                                                            </tr>
                                                        )}
                                                        {propertyData.growthYesterdayVsLastWeek && (
                                                            <tr>
                                                                <td>Yesterday vs LW</td>
                                                                <td>{propertyData.growthYesterdayVsLastWeek.sales ? getStyledValue(propertyData.growthYesterdayVsLastWeek.sales) : 0}</td>
                                                                <td>0</td>
                                                                <td>{propertyData.growthYesterdayVsLastWeek.revenueShare ? getStyledValue(propertyData.growthYesterdayVsLastWeek.revenueShare) : 0}</td>
                                                                <td>0.00</td>
                                                                <td>{propertyData.growthYesterdayVsLastWeek.atv ? getStyledValue(propertyData.growthYesterdayVsLastWeek.atv) : 0}</td>
                                                            </tr>
                                                        )}
                                                        {propertyData.growthYesterdayVsLastYear && (
                                                            <tr>
                                                                <td>Yesterday vs LY</td>
                                                                <td>{propertyData.growthYesterdayVsLastYear.sales ? getStyledValue(propertyData.growthYesterdayVsLastYear.sales) : 0}</td>
                                                                <td>0</td>
                                                                <td>{propertyData.growthYesterdayVsLastYear.revenueShare ? getStyledValue(propertyData.growthYesterdayVsLastYear.revenueShare) : 0}</td>
                                                                <td>0.00</td>
                                                                <td>{propertyData.growthYesterdayVsLastYear.atv ? getStyledValue(propertyData.growthYesterdayVsLastYear.atv) : 0}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                )}
                                            </Table>
                                        </div>
                                    </Col>
                                </CardBody>
                            </Card>
                        ))}
                    </div>

                </Fragment>
            )}
        </Fragment>
    )
}

export default DailyComprehensiveRevenue