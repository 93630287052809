import React, { Fragment, useState, useEffect } from 'react';
import { Btn, Spinner } from '../../../../AbstractElements';
import { Card, CardBody, CardHeader, Col, Table, Form, FormGroup, Label, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import axios from 'axios';
import config from '../../../../config';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import { exportToExcel, exportToPDF } from '../ExportReport';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { faFileExcel, faFilePdf, faRedo } from '@fortawesome/free-solid-svg-icons';

const RevenueShareReportView = () => {

    if (localStorage.getItem("accessReports") !== "1") {
        alert('You are not authorized to view this page');
        window.history.back(); // Navigate back to the previous URL
    }

    const currentYear = new Date().getFullYear();
    const concessionaire_id = localStorage.getItem("concessionaireId");
    const property_access = localStorage.getItem("propertyAccess");
    const [propertyAccessNames, setPropertyAccesssNames] = useState('');
    const login_name = localStorage.getItem("loginName");
    const login_id = localStorage.getItem("loginId");
    const [year, setYear] = useState(currentYear);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [properties, setProperties] = useState([]);
    const [categories, setCategories] = useState([]);
    const [allSubcategories, setAllSubcategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [group_name, setGroupName] = useState('');
    const [groups, setGroups] = useState([]);
    const [allTenants, setAllTenants] = useState([]);
    const [tenants, setTenants] = useState([]);
    const [allFloors, setAllFloors] = useState([]);
    const [floors, setFloors] = useState([]);
    const [property_id, setPropertyId] = useState('');
    const [category_id, setCategoryId] = useState('');
    const [subcategory_id, setSubcategoryId] = useState('');
    const [tenant_id, setTenantId] = useState('');
    const [selectedFloor, setSelectedFloor] = useState('');
    const [ReportData, setReportData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage] = useState(50);
    const [validationMessage, setValidationMessage] = useState('');
    const [loading, setLoading] = useState(false)
    const today = new Date().toISOString().split('T')[0];
    const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [tooltip, setTooltip] = useState('');

    const handleMouseEnter = (text) => setTooltip(text);
    const handleMouseLeave = () => setTooltip('');

    const token = localStorage.getItem("sessiontoken");
    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    useEffect(() => {
        axios
            .post(`${config.hostname}/property/getpropertybyconcessionaire`, { property_concessionaire_id: concessionaire_id, property_id: property_access }, configheader)
            .then((response) => {
                setProperties(response.data);
                const propertyNames = response.data.map(property => property.property_name).join(',');
                setPropertyAccesssNames(propertyNames);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);


    useEffect(() => {
        axios
            .post(`${config.hostname}/category/getcategorybyconcessionaire`, { category_concessionaire_id: concessionaire_id }, configheader)
            .then((response) => {
                setCategories(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios
            .post(`${config.hostname}/subcategory/getsubcategorybyconcessionaire`, { subcategory_concessionaire_id: concessionaire_id }, configheader)
            .then((response) => {
                setAllSubcategories(response.data);
                setSubcategories(response.data); // Initial set to all subcategories
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        let filteredSubcategories = allSubcategories;

        if (category_id !== '') {
            filteredSubcategories = filteredSubcategories.filter(subcategory => subcategory.category_id._id === category_id);
        }

        setSubcategories(filteredSubcategories);
    }, [category_id]);

    useEffect(() => {
        axios
            .post(`${config.hostname}/tenant/getenantsbyconcessionaire`, { tenant_concessionaire_id: concessionaire_id, property_id: property_access }, configheader)
            .then((response) => {
                setAllTenants(response.data);
                setTenants(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        axios
            .post(`${config.hostname}/tenant/getgroupnames`, { tenant_concessionaire_id: concessionaire_id, property_id: property_access }, configheader)
            .then((response) => {
                setGroups(response.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        let filteredTenants = allTenants;

        if (group_name !== '') {
            filteredTenants = filteredTenants.filter(tenant => tenant.tenant_group_name === group_name);
        }

        if (category_id !== '') {
            filteredTenants = filteredTenants.filter(tenant => tenant.tenant_category_id._id === category_id);
        }

        if (property_id !== '') {
            filteredTenants = filteredTenants.filter(tenant => tenant.tenant_property_id._id === property_id);
        }

        if (subcategory_id !== '') {
            filteredTenants = filteredTenants.filter(tenant => tenant.tenant_subcategory_id._id === subcategory_id);
        }

        setTenants(filteredTenants);
    }, [group_name, property_id, category_id, subcategory_id, allTenants]);


    useEffect(() => {
        axios
            .post(`${config.hostname}/property/getfloorsbyconcessionaire`, { concessionaire_id, property_id: property_access }, configheader)
            .then((response) => {
                setAllFloors(response.data);
                setFloors(getUniqueFloors(response.data));
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    useEffect(() => {
        let filteredFloors = allFloors;

        if (property_id !== '') {
            filteredFloors = filteredFloors.filter(floor => floor.property_id === property_id);
        }

        setFloors(getUniqueFloors(filteredFloors));
    }, [property_id]);

    const getUniqueFloors = (floors) => {
        const floorNames = new Set();
        return floors.filter(floor => {
            if (!floorNames.has(floor.floor_id.floor_name)) {
                floorNames.add(floor.floor_id.floor_name);
                return true;
            }
            return false;
        });
    };

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
        setReportData([]);
        setCurrentPage(0);
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
        setReportData([]);
        setCurrentPage(0);
    };

    const handlePropertyChange = (e) => {
        setPropertyId(e.target.value);
        setTenantId('');
        setSelectedFloor('');
        setReportData([]);
        setCurrentPage(0);
    };

    const handleCategoryChange = (e) => {
        setCategoryId(e.target.value);
        setSubcategoryId('')
        setTenantId('');
        setReportData([]);
        setCurrentPage(0);
    };

    const handleSubCategoryChange = (e) => {
        setSubcategoryId(e.target.value);
        setTenantId('');
        setReportData([]);
        setCurrentPage(0);
    };

    const handleGroupChange = (e) => {
        setGroupName(e.target.value);
        setTenantId('');
        setReportData([]);
        setCurrentPage(0);
    };

    const handleTenantChange = (e) => {
        setTenantId(e.target.value);
        setReportData([]);
        setCurrentPage(0);
    };

    const handleFloorChange = (e) => {
        setSelectedFloor(e.target.value);
        setReportData([]);
        setCurrentPage(0);
    };

    const resetFilters = () => {
        setStartDate(null);
        setEndDate(null);
        setPropertyId('');
        setSelectedFloor('');
        setCategoryId('');
        setSubcategoryId('');
        setGroupName('');
        setTenantId('');
        setReportData([]);
        setCurrentPage(0);
    };

    const generateReport = async (e) => {
        e.preventDefault();


        if (endDate < startDate) {
            setValidationMessage('End month cannot be less than start month');
            return;
        } else {
            setValidationMessage('');
        }

        const category_name = category_id !== '' ? categories.find(category => category._id === category_id).category_name : '';
        const property_name = property_id !== '' ? properties.find(property => property._id === property_id).property_name : '';
        const subcategory_name = subcategory_id !== '' ? subcategories.find(subcategory => subcategory._id === subcategory_id).subcategory_name : '';
        const tenant_name = tenant_id !== '' ? tenants.find(tenant => tenant._id === tenant_id).tenant_store_name : '';

        const data = {
            concessionaire_id,
            property_access: propertyAccessNames,
            year,
            start_date: startDate,
            end_date: endDate,
            property_name,
            category_name,
            subcategory_name,
            group_name,
            tenant_name,
            floor: selectedFloor
        };

        setLoading(true)
        axios
            .post(`${config.hostname}/reports/getrevenuesharereport`, data, configheader)
            .then((response) => {
                setReportData(response.data);
                setLoading(false)
                insertToLog(" generated Revenue Share Report")
                console.log(response.data);
            })
            .catch((error) => {
                toast.error('Error');
                setLoading(false)
                console.log(error);
            });
    };



    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    const offset = currentPage * itemsPerPage;
    //const currentPageData = ReportData.slice(offset, offset + itemsPerPage);
    const pageCount = Math.ceil(ReportData.length / itemsPerPage);

    const totalTransactionCount = ReportData.reduce((sum, item) => sum + item.total_transaction_count, 0);
    const totalNetSales = ReportData.reduce((sum, item) => sum + item.total_net_sales, 0);


    const sortedReportData = React.useMemo(() => {
        let sortableData = [...ReportData];
        if (sortConfig.key !== '') {
            sortableData.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableData;
    }, [ReportData, sortConfig]);

    const currentPageData = sortedReportData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const getSortIcon = key => {
        if (sortConfig.key === key) {
            if (sortConfig.direction === 'asc') {
                return <FontAwesomeIcon icon={faSortUp} style={{ fontSize: '0.5em', fontWeight: 'normal' }} />;
            }
            if (sortConfig.direction === 'desc') {
                return <FontAwesomeIcon icon={faSortDown} style={{ fontSize: '0.5em', fontWeight: 'normal' }} />;
            }
        }

    };


    const insertToLog = (task) => {
        const data = {
            created_by: login_id,
            module: "Reports",
            task: login_name + task
        }
        axios
            .post(`${config.hostname}/log`, data, configheader)
            .then(() => {
                console.log("inserted to log table")
            })
            .catch((error) => {
                toast.error('Error')
                console.log(error);

            });
    }

    const handleExport = (format) => {
        if (ReportData.length > 0) {
            const metadata = [
                ['From ', startDate, ' To:', endDate],
                ['Property ', property_id ? properties.find(p => p._id === property_id)?.property_name : 'All'],
                ['Category', category_id ? categories.find(s => s._id === category_id)?.category_name : 'All'],
                ['Subcategory ', subcategory_id ? subcategories.find(s => s._id === subcategory_id)?.subcategory_name : 'All'],
                ['Group ', group_name ? group_name : 'All'],
                ['Tenant ', tenant_id ? tenants.find(t => t._id === tenant_id)?.tenant_store_name : 'All'],
                ['Floor ', selectedFloor || 'All'],
                ['Exported By ', login_name],
                ['Exported On ', new Date().toLocaleString()]
            ];
            const reportColumns = [
                { header: 'Tenant Name', key: 'tenant_store_name' },
                { header: 'Group Name', key: 'tenant_group_name' },
                { header: 'Tenant Code', key: 'tenant_code' },
                { header: 'Unit Code', key: 'tenant_unit_code' },
                { header: 'Property', key: 'property_name' },
                { header: 'Subcategory', key: 'subcategory_name' },
                { header: 'Floor', key: 'floor' },
                { header: 'Transaction Count', key: 'total_transaction_count' },
                {
                    header: 'Net Sales', key: 'total_net_sales', format: value => Number(value).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })
                },
                { header: 'Base Rent', key: 'base_rent' },
                { header: 'Revenue Share %', key: 'revenue_share_percentage' },
                {
                    header: 'Revenue Share Amount', key: 'revenue_share_amount', format: value => Number(value).toLocaleString('en-US', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                    })
                },
                { header: 'Store Area', key: 'store_area' },
                {
                    header: 'Minimum Rent per SQF', key: 'minimum_rent_per_sqft', format: value => Number(value).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })
                },
                { header: 'Lease Start Date', key: 'lease_start_date' },
                { header: 'Lease End Date', key: 'lease_end_date' },
            ];
            if (format === 'excel') {
                exportToExcel("Revenue Share Report", metadata, reportColumns, ReportData, "RevenueShareReport.xlsx");
                insertToLog(" exported Revenue Share Report to Excel")
            } else if (format === 'pdf') {
                exportToPDF("Revenue Share Report", metadata, reportColumns, ReportData, "RevenueShareReport.pdf", true);
                insertToLog(" exported Revenue Share Report to pdf")
            }
        }
    };

    const reset = () => {
        setStartDate('')
        setEndDate('')
        setPropertyId('');
        setCategoryId('');
        setSubcategoryId('');
        setGroupName('');
        setTenantId('');
        setSelectedFloor('');
        setReportData([])

    }

    return (
        <Fragment>
            <Form className='theme-form' onSubmit={generateReport}>
                <Card>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="month">Start Date</Label>
                                    <input className="form-control digits"
                                        id="startdate" type="date" name="startdate" value={startDate}
                                        max={today} onChange={handleStartDateChange} required />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="month">End Date</Label>
                                    <input className="form-control digits"
                                        id="startdate" type="date" name="startdate" value={endDate}
                                        max={today} onChange={handleEndDateChange} required />
                                </FormGroup>
                                {validationMessage && (
                                    <span className="text-danger">{validationMessage}</span>
                                )}
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="property_id">Property</Label>
                                    <select name="property_id" id="property_id" className="form-control"
                                        value={property_id} onChange={handlePropertyChange}>
                                        <option value="">All</option>
                                        {properties.map(property => (
                                            <option key={property._id} value={property._id}>{property.property_name}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_id">Floor</Label>
                                    <select name="tenant_id" id="tenant_id" className="form-control"
                                        value={selectedFloor} onChange={handleFloorChange}>
                                        <option value="">All</option>
                                        {floors.map((floor, index) => (
                                            <option key={index} value={floor.floor_id.floor_name}>{floor.floor_id.floor_name}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>

                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="category_id">Category</Label>
                                    <select name="category_id" id="category_id" className="form-control"
                                        value={category_id} onChange={handleCategoryChange}>
                                        <option value="">All</option>
                                        {categories.map(category => (
                                            <option key={category._id} value={category._id}>{category.category_name}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="subcategory_id">Sub Category</Label>
                                    <select name="subcategory_id" id="subcategory_id" className="form-control"
                                        value={subcategory_id} onChange={handleSubCategoryChange}>
                                        <option value="">All</option>
                                        {subcategories.map(subcategory => (
                                            <option key={subcategory._id} value={subcategory._id}>{subcategory.subcategory_name}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="subcategory_id">Group</Label>
                                    <select name="subcategory_id" id="subcategory_id" className="form-control"
                                        value={group_name} onChange={handleGroupChange}>
                                        <option value="">All</option>
                                        {groups.map((group, index) => (
                                            <option key={index} value={group}>{group}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className="mb-3">
                                    <Label htmlFor="tenant_id">Tenant</Label>
                                    <select name="tenant_id" id="tenant_id" className="form-control"
                                        value={tenant_id} onChange={handleTenantChange}>
                                        <option value="">All</option>
                                        {tenants.map(tenant => (
                                            <option key={tenant._id} value={tenant._id}>{tenant.tenant_store_name}</option>
                                        ))}
                                    </select>
                                </FormGroup>
                            </Col>

                        </Row>
                        <Row>
                            <Col >
                                <Btn attrBtn={{ color: 'primary', className: 'me-3', type: "submit" }} >Generate Report</Btn>
                                <Btn attrBtn={{ color: 'primary', className: 'me-3', onClick: reset }} >Reset</Btn>
                                {/* <Btn
                                    attrBtn={{
                                        color: 'primary',
                                        type: 'button',
                                        //size: 'xs',
                                        onClick: () => resetFilters(),
                                        onMouseEnter: () => handleMouseEnter('Reset Filters'),
                                        onMouseLeave: handleMouseLeave
                                    }}
                                >
                                    <FontAwesomeIcon icon={faRedo} size="lg" />
                                </Btn>
                                {tooltip === 'Reset Filters' && (
                                    <div style={{
                                        position: 'absolute',
                                        top: '-30px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        backgroundColor: 'black',
                                        color: 'white',
                                        padding: '5px 10px',
                                        borderRadius: '3px',
                                        whiteSpace: 'nowrap',
                                        fontSize: '12px',
                                        zIndex: 1,
                                    }}>
                                        {tooltip}
                                    </div>
                                )} */}
                            </Col>
                            <Col >

                            </Col>

                        </Row>
                    </CardBody>
                </Card>
                {loading === true ? (
                    <div className="loader-box">
                        <Spinner attrSpinner={{ className: 'loader-3' }} />
                    </div>

                ) : (
                    <Card>
                        <CardHeader>
                            <Row className="justify-content-end">
                                <Col xs="auto" style={{ position: 'relative' }}>
                                    <Btn
                                        attrBtn={{
                                            color: 'primary',
                                            type: 'button',
                                            onClick: () => handleExport('excel'),
                                            onMouseEnter: () => handleMouseEnter('Export to Excel'),
                                            onMouseLeave: handleMouseLeave
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faFileExcel} size="lg" />
                                    </Btn>
                                    {tooltip === 'Export to Excel' && (
                                        <div style={{
                                            position: 'absolute',
                                            top: '-30px',
                                            left: '50%',
                                            transform: 'translateX(-50%)',
                                            backgroundColor: 'black',
                                            color: 'white',
                                            padding: '5px 10px',
                                            borderRadius: '3px',
                                            whiteSpace: 'nowrap',
                                            fontSize: '12px',
                                            zIndex: 1,
                                        }}>
                                            {tooltip}
                                        </div>
                                    )}
                                </Col>
                                <Col xs="auto" style={{ position: 'relative' }}>
                                    <Btn
                                        attrBtn={{
                                            color: 'primary',
                                            type: 'button',
                                            onClick: () => handleExport('pdf'),
                                            onMouseEnter: () => handleMouseEnter('Export to PDF'),
                                            onMouseLeave: handleMouseLeave
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faFilePdf} size="lg" />
                                    </Btn>
                                    {tooltip === 'Export to PDF' && (
                                        <div style={{
                                            position: 'absolute',
                                            top: '-30px',
                                            left: '50%',
                                            transform: 'translateX(-50%)',
                                            backgroundColor: 'black',
                                            color: 'white',
                                            padding: '5px 10px',
                                            borderRadius: '3px',
                                            whiteSpace: 'nowrap',
                                            fontSize: '12px',
                                            zIndex: 1,
                                        }}>
                                            {tooltip}
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Col sm="12" lg="12" xl="12">
                                <div className="table-responsive">
                                    <Table>

                                        <thead>
                                            <tr>
                                                <th scope="col" onClick={() => requestSort('tenant_store_name')}>Tenant Name{getSortIcon('tenant_store_name')}</th>
                                                <th scope="col" onClick={() => requestSort('tenant_group_name')}>Group Name{getSortIcon('tenant_group_name')}</th>
                                                <th scope="col" onClick={() => requestSort('tenant_code')}>Tenant Code{getSortIcon('tenant_code')}</th>
                                                <th scope="col" onClick={() => requestSort('tenant_unit_code')}>Unit Code{getSortIcon('tenant_unit_code')}</th>
                                                <th scope="col" onClick={() => requestSort('property_name')}>Property{getSortIcon('property_name')}</th>
                                                <th scope="col" onClick={() => requestSort('subcategory_name')}>Subcategory{getSortIcon('subcategory_name')}</th>
                                                <th scope="col" onClick={() => requestSort('floor')}>Floor{getSortIcon('floor')}</th>
                                                <th scope="col" onClick={() => requestSort('total_transaction_count')}>Transaction Count{getSortIcon('total_transaction_count')}</th>
                                                <th scope="col" onClick={() => requestSort('total_net_sales')}>Net Sales{getSortIcon('total_net_sales')}</th>
                                                <th scope="col" onClick={() => requestSort('base_rent')}>Base Rent{getSortIcon('base_rent')}</th>
                                                <th scope="col" onClick={() => requestSort('revenue_share_percentage')}>Revenue Share %{getSortIcon('revenue_share_percentage')}</th>
                                                <th scope="col" onClick={() => requestSort('revenue_share_amount')}>Revenue Share Amount{getSortIcon('revenue_share_amount')}</th>
                                                <th scope="col" onClick={() => requestSort('store_area')}>Store Area{getSortIcon('store_area')}</th>
                                                <th scope="col" onClick={() => requestSort('minimum_rent_per_sqft')}>Minimum Rent per SQF{getSortIcon('minimum_rent_per_sqft')}</th>
                                                {/* <th scope="col" onClick={() => requestSort('rent_per_sqft')}>Rent per SQF{getSortIcon('rent_per_sqft')}</th> */}
                                                <th scope="col" onClick={() => requestSort('lease_start_date')}>Lease Start Date{getSortIcon('lease_start_date')}</th>
                                                <th scope="col" onClick={() => requestSort('lease_end_date')}>Lease End Date{getSortIcon('lease_end_date')}</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentPageData.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.tenant_store_name}</td>
                                                    <td>{item.tenant_group_name}</td>
                                                    <td>{item.tenant_code}</td>
                                                    <td>{item.tenant_unit_code}</td>
                                                    <td>{item.property_name}</td>
                                                    <td>{item.subcategory_name}</td>
                                                    <td>{item.floor}</td>
                                                    <td>{item.total_transaction_count}</td>
                                                    {/* <td>{parseFloat(item.total_net_sales).toFixed(2)}</td> */}
                                                    <td>{item.total_net_sales
                                                        ? parseFloat(item.total_net_sales).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                        })
                                                        : ''}</td>
                                                    {/* <td>{item.base_rent}</td> */}
                                                    <td>{item.base_rent
                                                        ? parseFloat(item.base_rent).toLocaleString(undefined, {
                                                            minimumFractionDigits: 0,
                                                            maximumFractionDigits: 0,
                                                        })
                                                        : ''}</td>
                                                    <td>{item.revenue_share_percentage}</td>
                                                    {/* <td>{parseFloat(item.revenue_share_amount).toFixed(2)}</td> */}
                                                    <td>{item.revenue_share_amount
                                                        ? parseFloat(item.revenue_share_amount).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                        })
                                                        : ''}</td>
                                                    <td>{item.store_area}</td>
                                                    {/* <td>{item.minimum_rent_per_sqft}</td> */}
                                                    <td>{item.minimum_rent_per_sqft
                                                        ? parseFloat(item.minimum_rent_per_sqft).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                        })
                                                        : ''}</td>
                                                    <td>{item.lease_start_date}</td>
                                                    <td>{item.lease_end_date}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan="7">Total</td>
                                                <td>{totalTransactionCount}</td>
                                                <td>{totalNetSales.toLocaleString()}</td>
                                            </tr>
                                        </tfoot>
                                    </Table>
                                </div>
                                {ReportData.length > 50 && (
                                    <ReactPaginate
                                        previousLabel={'<<'}
                                        nextLabel={'>>'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={'pagination'}
                                        subContainerClassName={'pages pagination'}
                                        activeClassName={'active'}
                                        pageClassName={'page-item'}
                                        pageLinkClassName={'page-link'}
                                    />
                                )}
                            </Col>
                        </CardBody>
                    </Card>
                )}
            </Form>
        </Fragment >
    )
}

export default RevenueShareReportView