import React, { useEffect, useState, Fragment } from 'react'
import axios from 'axios';
import config from '../../../../config';
import { CardBody, Table, Col, Card } from 'reactstrap';
import HeaderCard from '../../../Common/Component/HeaderCard';

const GrowthAnalysis = ({ tenantId }) => {
    const concessionaire_id = localStorage.getItem("concessionaireId");
    const token = localStorage.getItem("sessiontoken");
    const year = new Date().getFullYear()

    const [monthlyAnalysisData, setMonthlyAnalysisData] = useState({ currentYearSales: [], previousYearSales: [] });

    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    useEffect(() => {
        const data = {
            concessionaire_id,
            year: year,
            tenant_id: tenantId,
        };

        axios
            .post(`${config.hostname}/tenantdashboard/getgrowthanalysis`, data, configheader)
            .then((response) => {
                setMonthlyAnalysisData(response.data);
            })
            .catch((error) => {
                console.log(error);
            });


    }, [tenantId])


    const getStyledValue = (value) => {
        const numericValue = parseFloat(value);
        let style = {};
        let arrow = '';

        if (numericValue > 0) {
            style = { color: 'green' };
            arrow = '↑';
        } else if (numericValue < 0) {
            style = { color: 'red' };
            arrow = '↓';
        }

        return (
            <span style={style}>
                {value}% {arrow}
            </span>
        );
    };

    return (
        <Fragment>
            <Col sm='12' >
                <Card>
                    <HeaderCard title={<div><div style={{ display: 'flex', alignItems: 'center' }}>
                        <div>{'Growth Analysis '}</div>
                        <div style={{ fontSize: '0.7em', color: '#777', marginLeft: '10px' }}>Year on Year</div>
                    </div>
                    </div>} />
                    <CardBody>
                        <Col sm="12" lg="12" xl="12">
                            <div className="table-responsive">

                                <Table>
                                    <thead>
                                        <tr>
                                            <th scope="col">Month</th>
                                            <th scope="col">{year - 1}</th>
                                            <th scope="col">{year}</th>
                                            <th scope="col">YOY Change</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {monthlyAnalysisData.previousYearSales.map((item, index) => {
                                            const currentYearData = monthlyAnalysisData.currentYearSales[index];
                                            return (
                                                <tr key={index}>
                                                    <td>{item.month}</td>
                                                    <td>{Number(item.totalSales).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                    <td>
                                                        {Number(currentYearData.totalSales).toLocaleString('en-US', {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2
                                                        })}
                                                    </td>
                                                    <td>{parseFloat(currentYearData.YOYChange) === 0 || parseFloat(currentYearData.YOYChange) === -100 || currentYearData.YOYChange === '-' ? '-' : getStyledValue(currentYearData.YOYChange)}</td>
                                                    <td></td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    )
}

export default GrowthAnalysis