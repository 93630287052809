import React, { Fragment, useState, useEffect } from 'react';
import { Col, Card, CardBody } from 'reactstrap';
import HeaderCard from '../../../Common/Component/HeaderCard';
import DataTable from 'react-data-table-component';
import config from '../../../../config';
import axios from 'axios';
import { Btn } from '../../../../AbstractElements';
import { useNavigate } from 'react-router';
import { colors } from '@mui/material';

const MonthOnMonthTable = ({ tenantId }) => {
    const concessionaire_id = localStorage.getItem("concessionaireId");
    const property_id = localStorage.getItem("propertyAccess");
    const token = localStorage.getItem("sessiontoken");
    const currentYear = new Date().getFullYear();
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const [currentYearData, setCurrentYearData] = useState([]);
    const [previousYearData, setPreviousYearData] = useState([]);
    const [apiUrl, setApiUrl] = useState('');
    const [apiData, setApiData] = useState([]);

    const configheader = {
        headers: {
            Authorization: `${token}`
        }
    };

    const navigate = useNavigate();
    const layout = localStorage.getItem("layout");

    useEffect(() => {

        const apiData = {
            concessionaire_id,
            tenant_id: tenantId,
            property_id
        }
        axios
            .post(`${config.hostname}/tenantdashboard/getsalescomparisonmom`, apiData, configheader)
            .then((response) => {
                setCurrentYearData(transformSalesData(response.data.currentYearSales));
                setPreviousYearData(transformSalesData(response.data.previousYearSales));
            })
            .catch((error) => {
                console.log(error);
            });

    }, [tenantId]);



    const transformSalesData = (salesData) => {
        return salesData.reduce((acc, item) => {
            acc[item.month] = {
                totalSales: item.totalSales,
                percentageChange: item.percentageChange
            };
            return acc;
        }, {});
    };



    const tableColumns = [
        {
            name: 'Year',
            selector: row => row.year,
            sortable: false,
            center: false,
            width: '80px'
        },
        ...monthNames.map(month => ({
            name: month.substr(0, 3),
            selector: month,
            sortable: false,
            center: true,
            width: '90px',
            format: row => {
                const value = row[month];
                const sales = value?.totalSales ? `${value.totalSales} ` : '-';
                const change = value?.percentageChange ? `${value.percentageChange}%` : '-';

                // Set color based on the percentage change
                const color = value?.percentageChange > 0 ? 'green' : (value?.percentageChange < 0 ? 'red' : 'black');

                return (
                    <div style={{ textAlign: 'center' }}>
                        <div>{sales}</div>
                        <div style={{ color, fontSize: '0.8em' }}>
                            {value?.percentageChange > 0 ? `↑ ${change}` : (value?.percentageChange < 0 ? `↓ ${change}` : `${change}`)}
                        </div>
                    </div>
                );
            }

        }))

    ];

    const data = [
        { year: currentYear - 1, ...previousYearData },
        { year: currentYear, ...currentYearData }
    ];

    const handleMoreClick = () => {
        console.log('More clicked')
        navigate(`${process.env.PUBLIC_URL}/admin/report/growthanalysisreport/${layout}`);
    }


    return (
        <Fragment>
            <Col sm='12' >
                <Card>
                    <HeaderCard
                        title={
                            <div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div>{'Sales Analysis '}</div>
                                    <div style={{ fontSize: '0.7em', color: '#777', marginLeft: '10px' }}>Month on month</div>
                                    <div style={{ marginLeft: 'auto' }}>
                                        <Btn
                                            attrBtn={{ color: 'primary', className: 'me-3', onClick: () => handleMoreClick() }}
                                        >
                                            More
                                        </Btn>
                                    </div>
                                </div>
                                <div style={{ fontSize: '0.6em', color: '#777' }}>Values in millions</div>
                            </div>
                        }
                    />


                    <CardBody>
                        <DataTable
                            data={data}
                            columns={tableColumns}
                            striped={true}
                            center={true}
                        />
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    )
}

export default MonthOnMonthTable